import ApiService from '@/api/new/services/api.service'
import App from '@/App.vue'
import i18n from '@/i18n'
import '@/registerServiceWorker'
import { router } from '@/router/index'
import store from '@/store/store'
import clickOutside from '@/utils/clickOutside'

import { config, library } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import {
  faAlignLeft,
  faAngleLeft,
  faAngleRight,
  faAnglesUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowRightToBracket,
  faArrowsRotate,
  faArrowUpRightFromSquare,
  faBars,
  faBarsStaggered,
  faBell,
  faBold,
  faBookmark,
  faBookOpen,
  faBuilding,
  faCalendarAlt,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleExclamation,
  faCircleInfo,
  faCirclePlay,
  faCircleXmark,
  faClipboard,
  faClock,
  faClockRotateLeft,
  faCodeBranch,
  faCog,
  faCoins,
  faCopy,
  faDatabase,
  faDisplay,
  faDownload,
  faEarthEurope,
  faEllipsisV,
  faEllipsisVertical,
  faExclamation,
  faEye,
  faFile,
  faFileArrowDown,
  faFileArrowUp,
  faFileCirclePlus,
  faFileLines,
  faFilter,
  faFlagCheckered,
  faFolderOpen,
  faGears,
  faGlobe,
  faGripLines,
  faHashtag,
  faHeading,
  faHouse,
  faItalic,
  faKey,
  faLanguage,
  faLink,
  faLocationPin,
  faMagnifyingGlass,
  faMicrochip,
  faPaperclip,
  faPaperPlane,
  faPen,
  faPencil,
  faPlay,
  faPlus,
  faPlusSquare,
  faPuzzlePiece,
  faQuestion,
  faRotateLeft,
  faRotateRight,
  faSignOutAlt,
  faSliders,
  faSquare,
  faStairs,
  faStar,
  faTableList,
  faTimes,
  faTrash,
  faTriangleExclamation,
  faUnderline,
  faUpload,
  faUser,
  faUserGear,
  faUserPlus,
  faUsers,
  faUserShield,
  faXmark
} from '@fortawesome/free-solid-svg-icons'
import './index.css'

import {
  faHourglassHalf,
  faFloppyDisk as FaRefularFloppyDisk,
  faCircleDown as faRegularCircleDown,
  faCirclePlay as faRegularfaCirclePlay,
  faPenToSquare as faRegularPenToSquare,
  faStar as faRegularStar
} from '@fortawesome/free-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import * as Sentry from '@sentry/vue'
import Echo from 'laravel-echo'
import 'regenerator-runtime/runtime.js'
import { Calendar } from 'v-calendar'
import 'v-calendar/dist/style.css'
import { createApp } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
import InlineSvg from 'vue-inline-svg'
import VueCookies from 'vue3-cookies'

library.add(
  faUpload,
  faMagnifyingGlass,
  faHouse,
  faQuestion,
  faPuzzlePiece,
  faCircleCheck,
  faDownload,
  faPencil,
  faPlus,
  faTableList,
  faDatabase,
  faDisplay,
  faCodeBranch,
  faBarsStaggered,
  faUsers,
  faCopy,
  faCog,
  faSignOutAlt,
  faBell,
  faFilter,
  faChevronLeft,
  faChevronRight,
  faFolderOpen,
  faFile,
  faClockRotateLeft,
  faPen,
  faCheck,
  faCheckSquare,
  faTrash,
  faCircleInfo,
  faSquare,
  faPlay,
  faGears,
  faAnglesUp,
  faStar,
  faUserShield,
  faUserPlus,
  faBookOpen,
  faCircleExclamation,
  faCircleXmark,
  faArrowsRotate,
  faArrowsRotate,
  faLocationPin,
  faUserGear,
  faMicrochip,
  faBold,
  faItalic,
  faLink,
  faPaperPlane,
  faUnderline,
  faPaperclip,
  faTimes,
  faExclamation,
  faEllipsisVertical,
  faCaretDown,
  faEarthEurope,
  faCaretUp,
  faHashtag,
  faBookmark,
  faFileArrowDown,
  faFileArrowUp,
  faAngleRight,
  faAngleLeft,
  faBuilding,
  faLanguage,
  faGripLines,
  faTriangleExclamation,
  faRotateLeft,
  faRotateRight,
  faGripLines,
  faCalendarAlt,
  faChevronDown,
  faChevronUp,
  faEllipsisV,
  faClock,
  faBars,
  faFilter,
  faPlusSquare,
  faArrowRightToBracket,
  faAlignLeft,
  faFileLines,
  faArrowLeft,
  faArrowDown,
  faArrowRight,
  faKey,
  faClipboard,
  faRegularStar,
  faRegularPenToSquare,
  faRegularfaCirclePlay,
  faRegularCircleDown,
  faHourglassHalf,
  faFlagCheckered,
  faHeading,
  faXmark,
  faStairs,
  faGlobe,
  faFileCirclePlus,
  faEarthEurope,
  faCoins,
  faCirclePlay,
  faArrowUpRightFromSquare,
  FaRefularFloppyDisk,
  faUser,
  faEye,
  faCircleChevronLeft,
  faCircleChevronRight,
  faSliders
)

const app = createApp(App)

app.component('FontAwesomeIcon', FontAwesomeIcon)
app.component('DraggableWrapper', VueDraggableNext)
app.component('InlineSvg', InlineSvg)

// Directives
app.directive('click-outside', clickOutside)

app.use(VueCookies, {
  secure: true
})

const { $cookies } = app.config.globalProperties
const env = $cookies.get('env')
if (env !== 'sandbox') {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracePropagationTargets: [
      'localhost',
      'test.dev.localhost',
      'https://platform.testing.easytranslate.com',
      'https://platform.easytranslate.com',
      /^\//
    ],
    environment: process.env.VUE_APP_ENV,
    tracesSampleRate: 1.0,
    release: `v${process.env.VUE_APP_RELEASE_VERSION}`
  })
}

app.config.productionTip = false

app.config.errorHandler = function (err) {
  Sentry.captureException(err)
}
if (env === 'production') {
  app.config.silent = true
  app.config.debug = false
} else {
  app.config.devtools = true
}

window.Pusher = require('pusher-js')
const API_URL =
  env === 'sandbox'
    ? process.env.VUE_APP_ROOT_API_SANDBOX
    : process.env.VUE_APP_ROOT_API
const PUSHER_KEY =
  env === 'sandbox'
    ? process.env.VUE_APP_MIX_PUSHER_APP_KEY_SANDBOX
    : process.env.VUE_APP_MIX_PUSHER_APP_KEY
const EchoInstance = new Echo({
  broadcaster: 'pusher',
  key: PUSHER_KEY,
  cluster: process.env.VUE_APP_MIX_PUSHER_APP_CLUSTER,
  forceTLS: true,
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        fetch(`${API_URL}api/v1/broadcasting/auth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({
            socket_id: socketId,
            channel_name: channel.name
          })
        })
          .then((response) => response.json())
          .then((data) => {
            callback(false, data)
          })
          .catch((error) => {
            callback(true, error)
          })
      }
    }
  }
})

config.autoAddCss = false

app.config.globalProperties = {
  ...app.config.globalProperties,
  $echo: EchoInstance,
  $i18n: i18n.i18n
}
// app.use(VueEcho, EchoInstance)

if (env === 'sandbox') {
  ApiService.init(process.env.VUE_APP_ROOT_API_SANDBOX)
} else {
  ApiService.init(process.env.VUE_APP_ROOT_API)
}

app.component('VCalendar', Calendar)
app.use(store)
app.use(router)
app.use(i18n.i18n)
app.mount('#app')

export { $cookies, EchoInstance as $echo, app }
